

/* Header */
header .container {
    display: flex;
    justify-content: space-between;
    font-family: 'Jost';
    position: relative;
    padding: 10px;
}

header .container::before {
    position: absolute;
    content: '';
    width: calc(100% - 30px);
    height: 1px;
    background-color: var(--color-6);
    bottom: 0;
    left: 15px;
}

header .container .logo {
    position: relative;
    cursor: pointer;
}
@media (max-width: 425px) {
    header .container .logo {
        font-size: 23px;
    }
}

header .container .logo::before {
    position: absolute;
    content: '';
    width: 15px;
    height: 15px;
    background-color: var(--color-7);
    right: -17px;
    border-radius: 50%;
    bottom: calc(50% - 10px);
}
@media (max-width: 425px) {
    header .container .logo::before {
        bottom: calc(50% - 8px);
    }
}

header .container > div > i {
    display: none;
}

header .container ul {
    flex: 0.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    gap: 20px;
}

@media (min-width: 768px) {
    header .container ul {
        opacity: 1 !important;
    }
}

@media (max-width: 425px) {
    header .container > div > i {
        display: block;
        font-size: 35px;
    }

    header .container {
        position: relative;
    }
    header .container ul {
        display: block;
        width: 200px;
        position: absolute;
        flex-direction: column;
        right: 12px;
        top: 55px;
        padding: 10px;
        z-index: 1;
        background-color: #1f1f1f89;
        transition: 0.5s;
    }
    header .container ul li {
        margin-bottom: 5px;
        border-bottom: 1px solid;
        padding: 10px;
    }
}

header .container ul>:last-child {
    position: relative;
    border: 1px solid black;
    padding: 5px;
    transition: 0.8s;
    overflow: hidden;
    z-index: 2;
    color: black;
}

header .container ul i {
    margin-right: 10px;
}

/* 
header .container ul>:last-child:hover {
    color: white;
} */

header .container ul>:last-child {
    color: white;
}


header .container ul>:last-child::before {
    content: '';
    position: absolute;
    background-color: var(--color-3);
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    transition: 0.5s;
    cursor: pointer;
    z-index: -1;
}


@media (max-width: 425px) {
    header .container ul>:last-child {
        border: none;
    }
    header .container ul>:last-child::before {
        display: none;
    }
}
header .container ul>:last-child:hover::before {
    left: 0;
}


header .container ul li:not(:last-child):hover {
    color: var(--color-7);
}