@import './colors.css';

.contact {
    padding-top: 100px;
    position: relative;
}

.contact .container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contact .container form {
    padding: 50px;
    background-color: #1f1f1f;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .contact .container form {
        padding: 20px;
    }
}

.contact .container form * {
    display: block;
    width: 700px;
}
@media (max-width: 768px) {
    .contact .container form * {
        width: 600px;
    }
}

@media (max-width: 425px) {
    .contact .container form * {
        width: 350px;
    }
}

@media (max-width: 375px) {
    .contact .container form {
        padding: 10px;
    }
    .contact .container form * {
        width: 320px;
    }
}

@media (max-width: 375px) {
    .contact .container form * {
        width: 280px;
    }
}

.contact .container form div {
    margin-bottom: 20px;
}
.contact .container form label {
    margin-bottom: 10px;
    font-size: 18px;
    position: relative;
    width: fit-content;
}
@media (max-width: 425px) {
    .contact .container form label {
        font-size: 14px;
    }

}

.contact .container form label::before {
    position: absolute;
    content: '*';
    right: -10px;
    color: var(--color-7);
}

.contact .container form input ,
.contact .container form textarea {
    padding: 20px;
    border-radius: 5px;
    background-color: #2e2e2e;
    color: white;
    /* border: white solid 1px; */
    border: none;
}

.contact .container form input:focus ,
.contact .container form textarea:focus {
    outline: none;
}

.contact .container form textarea {
    height: 200px;
    resize: none;
}

.contact .container form input[type="submit"] {
    width: 200px;
    cursor: pointer;
    background-color: var(--color-7);
    padding: 20px;
    color: black;
    border-radius: 15px;
    font-weight: bold;
    font-size: 15px;
    transition: 0.5s;
}

@media (max-width: 768px) {
    .contact .container form input[type="submit"] {
        width: 150px;
    }
}
@media (max-width: 425px) {
    .contact .container form input ,
    .contact .container form textarea,
    .contact .container form input[type="submit"] {
        padding: 15px;
    }
}

.contact .container form input[type="submit"]:hover {
    background-color: black;
    color: white;
}

.contact .container .sent {
    padding: 15px;
    border-left: 5px solid var(--color-7);
    align-self: flex-start;
    margin-top: 20px;
    margin-left: 15%;
    opacity: 0;
    transition: 1.5s;
}