@import './colors.css';

.main {
	display: flex;
	color: var(--text-color);
	font-family: "Fira Sans", sans-serif;
    padding-top: 100px;
}

.main .container {
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 30px;
	text-align: center;
}

.eyes {
	display: flex;
	justify-content: center;
	gap: 2px;
}

.eye {
	width: 80px;
	height: 80px;
	background-color: var(--color-7);
	border-radius: 50%;
	display: grid;
	place-items: center;
}

.eye__pupil {
    width: 30px;
    height: 30px;
    background-color: var(--color-5);
    border-radius: 50%;
    animation: movePupil 2s infinite ease-in-out;
    transform-origin: center center;
}

@keyframes movePupil {
    0%,
    100% {
        transform: translate(0, 0);
    }
    25% {
        transform: translate(-10px, -10px);
    }
    50% {
        transform: translate(10px, 10px);
    }
    75% {
        transform: translate(-10px, 10px);
    }
}

.error-page__button {
    text-decoration: none;
    border: 1px solid var(--color-7);
    font-size: 18px;
    font-weight: 200;
    padding: 15px 30px;
    border-radius: 15px;
    box-shadow: 0px 7px 0px -2px var(--color-7);
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;
}

.error-page__button:hover {
    box-shadow: none;
    background-color: var(--primary-color);
    color: #fff;
}