@import './colors.css';

.techs {
    padding-top: 50px;
    padding-bottom: 150px;
}

.techs .container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 50px;
    margin-top: 20px;
}

.techs .box {
    display: flex;
    flex-direction: column;
    /* border-radius: 15px; */
    /* height: 200px; */
}


.techs .container .box .tech {
    border-radius: 15px;    
    /* width: 150px;
    height: 150px; */
    width: 100px;
    height: 100px;
    margin: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    /* background-color: white; */
}

.techs .container .box .content {
    /* width: 150px; */
    margin: 10px auto;
    text-align: center;
    /* border-radius: 15px; */
    font-size: 25px;
}
