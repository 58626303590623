:root {
    /* Dark */
    --color-1: #FC491C;
    --color-2: #5465FF;
    --color-3: #262626;
    --color-4: #F2F3F7;
    --color-5: #121212;
    --color-6: #1F1F1F;
    --color-7: #26dcfc;
    --color-8: #601ED4;
    --main-padding: 15px;
}
