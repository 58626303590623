@import './colors.css';

.projects {
    padding-bottom: 150px;
}

@media (max-width: 425px) {
    .projects {
        padding-bottom: 50px;
    }
}

.projects .container .box {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-bottom: 20px;
    width: 100%;
}

@media (max-width: 425px) {
    .projects .container .box {
        flex-direction: column;
        gap: 0px;
        align-items: flex-start;
        margin-bottom: 50px;
    }
}

.projects .container .box > div {
    width: 50%;
}

@media (max-width: 425px) {
    .projects .container .box > div {
        width: 100%;
    }
}
.projects .container .box .text h2 {
    font-size: 50px;
}
@media (max-width: 425px) {
    .projects .container .box .text-description h2 {
        font-size: 30px;
    }
}
.projects .container .box .image {
    height: 500px;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 15px;
}
@media (max-width: 768px) {
    .projects .container .box {
        text-align: center;
    }
    .projects .container .box .image {
        height: 300px;
        width: 300px;
        order: -1 !important;
        margin: auto;
    }
}



.projects .container .box .links a {
    margin: auto;
}


.projects .container .visit {
    margin-top: 20px;
    text-align: center;
}

.projects .container .visit a {
    padding: 10px 15px;
    color: black;
    border-radius: 3px;
    background-color: var(--color-7);
    transition: 0.5s;
    font-size: 18px;
}


.projects .container .visit a:hover {
    background-color: black;
    color: white;
}

/* .projects .container .visit a:hover i {
    animation-name: bouncing;
    animation-iteration-count: infinite;
    animation-duration: 1s;
} */

@keyframes bouncing {
    0%, 10%, 20%, 50%, 80%, 100% {
        transform: translateX(0);
    }

    40%, 60% {
        transform: translateX(5px);
    }
}

.toggle {
    position: relative;
    margin-bottom: 50px;
    font-size: 20px;
}

.toggle button {
    font-size: 15px;
    background-color: #121212;
    padding: 10px;
    border: none;
    color: white;
    cursor: pointer;
}

.toggle button i {
    transform: translateY(2px) rotate(90deg);
    margin-left: 5px;
    transition: transform 0.5s;
}

.toggle ul {
    background-color: #1f1f1f2e;
    padding: 10px;
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s;
    border-radius: 5px;
}
.toggle ul li {
    margin: 10px 0px;
    cursor: pointer;
}