@import './colors.css';


.about {
    padding-top: 50px;
    padding-bottom: 150px;
}
@media (max-width: 425px) {
    .about {
        padding-bottom: 70px;
    }
}

.about .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* overflow: hidden; */
}
@media (max-width: 768px) {
    .about .container {
        flex-direction: column;
    }
}

.about .container > div {
    width: 50%;
}

.about .text-content {
    position: relative;
}

@media (max-width: 768px) {
    .about .container .text-content {
        margin-top: 20px;
        width: 100%;
        text-align: center;
    }
}

.about .text-content h3 {
    position: absolute;
    font-size: 30px;
    background-color: var(--color-8);
    color: white;
    padding: 10px;
    border-radius: 50px;
    right: 150px;
    top: 0;
}

.about .container .text-content .links  i {
    display: inline-block;
    margin-left: 10px;
    transform: rotate(45deg);
}
@media (max-width: 768px) {
    .about .container .links{
        width: fit-content;
        margin: auto;
    }
}

.about .container .image {
    height: 500px;
    width: 500px;
    background-color: var(--color-3);
    position: relative;
    border-radius: 50%;
    background-image: url('../assets/profile.jpeg');
    background-size: cover;
}

@media (max-width: 768px) {
    .about .container .image {
        height: 300px;
        width: 300px;
        order: -1;
    }
}

@media (max-width: 425px) {
    .about .container .image {
        height: 200px;
        width: 200px;
        order: -1;
    }
}

/* .about .container .image img {
    max-width: 100%;
} */

.about .container .image .shapes > div {
    position: absolute;
    border-radius: 50%;
    background-color: rgba(255, 255, 255);
}

.about .container .image .shapes .blur {
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.about .container .image .shapes .blur::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: white;
    /* blur */
    filter: blur(5px);
    -webkit-filter: blur(5px);
}

.about .container .image .shapes > div img {
    max-width: 70%;
    position: relative;
}

.about .container .image .shapes > .shape-1 {
    top: 350px;
    left: 100px;
}

.about .container .image .shapes > .shape-2 {
    top: 350px;
    right: 50px;
}

.about .container .image .shapes > .shape-3 {
    bottom: 250px;
    right: 80px;
}


.about .text-content h2 {
    font-size: 45px;
    margin-bottom: 20px;
    position: relative;
}
@media (max-width: 425px) {
    .about .text-content h2 {
        font-size: 30px;
    }
    .about .text-content .description p {
        font-size: 20px;
    }
}


.about .text-content p {
    font-size: 25px;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 95%;
}



.about .text-content .links {
    display: flex;
    gap: 40px;
    font-size: 20px;
    align-items: center;
}
@media (max-width: 425px) {
    .about .text-content .links {
        font-size: 15px;
    }
}


.about .text-content .links > :first-child {
    background-color: var(--color-8);
    color: white;
    padding: 15px 20px;
}

@media (max-width: 425px) {
    .about .text-content .links > :first-child {
        padding: 10px 15px;
    }
}
.about .text-content .links i {
    transform: rotate(45deg);
}