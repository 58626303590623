@import './colors.css';

.project a {
    color: var(--color-7);
}

.box .text h2 {
    margin: 15px 0px;
    font-size: 25px;
}
.box .text p ,
.box .text .date {
    color: #ababab;
}
.box .text > div {
    display: flex;
    justify-content: space-between;
}

span i {
    margin-right: 10px;
}

.box .image {
    width: 100%;
    height: 400px;
    /* background-size: contain; */
    background-size: cover;
    margin-bottom: 20px;
}


.box .text p {
    line-height: 1.5;
    font-size: 20px;
    font-family:Arial, Helvetica, sans-serif;
}

.box .text p img {
    display: block;
    max-width: 80%;
}

.techs {
    color: #ababab;
    padding: 0px;
}