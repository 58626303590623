@import './colors.css';

/* Global rules */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

a {
    text-decoration: none;
    color: inherit;
}

body {
    font-family: "Cairo", sans-serif;
    background-color: var(--color-5);
    color: white;
    min-height: 100vh;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}

/* About */


/* .about .container .text-content h2 {
    font-size: 45px;
    margin-bottom: 20px;
    position: relative;
} */

/* .about .container .text-content h2::before {
    position: absolute;
    content: 'FullStack Dev';
    font-size: 20px;
    background-color: var(--color-1);
    color: white;
    padding: 10px;
    border-radius: 50px;
    left: 180px;
    bottom: 55px;
} */

/* 
.about .container .text-content p {
    font-size: 25px;
    line-height: 1.5;
    margin-bottom: 20px;
}
.about .container .text-content .links {
    display: flex;
    gap: 40px;
    font-size: 20px;
    align-items: center;
}
.about .container .text-content .links > :first-child {
    background-color: var(--color-2);
    color: white;
    padding: 15px 20px;
} */




/* projects */

/* .projects {
    padding-top: var(--main-padding);
    padding-bottom: 150px;
} */

.projects .container .content {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;
}