@import './colors.css';

.specialHeading {
    text-align: center;
    padding-bottom: 20px;
}

.specialHeading > :first-child {
    font-weight: 200;
    color: #bab5b5;
}
@media (max-width: 425px) {
    .specialHeading > :first-child {
        font-size: 20px;
    }
}
.specialHeading > :nth-child(2) {
    font-size: 40px;
    color: white;
}
@media (max-width: 425px) {
    .specialHeading > :nth-child(2) {
        font-size: 22px;
    }
}