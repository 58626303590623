.text-description span {
    color: var(--color-7);
    font-size: 23px;
}

.text-description h2 {
    font-size: 45px;
    margin-bottom: 20px;
    position: relative;
    color: white;
}

.text-description p {
    color: #b0b0b0;
    font-weight: 200;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 20px;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

@media (max-width: 768px) {
    .text-description p {
        -webkit-line-clamp: 2; /* number of lines to show */
        margin: auto;
        margin-bottom: 20px;
    }
}

@media (max-width: 425px) {
    .text-description span {
        display: block;
        padding-top: 30px;
    }
}


.text-description .links {
    font-size: 20px;
}

.text-description .links > :first-child {
    background-color: black;
    color: white;
    border: 0.75px solid #363636;
    padding: 15px 20px;
    border-radius: 50%;
    transition: 0.5s;
}

.text-description .links > :first-child:hover {
    background-color: var(--color-7);
    color: black;
    border: 0.75px solid var(--color-7);
}

.text-description .links i {
    transform: rotate(45deg);
}
