@import '../css/colors.css';

footer {
    position: absolute;
    background-color: black;
    width: 100%;
    bottom: -200px;
}


footer .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 50px;
}
@media (max-width: 425px) {
    footer .container {
        padding: 20px;
        flex-direction: column;
        align-items: flex-start;
    }
    footer .container .box {
        margin-bottom: 10px;
    }
}

footer .container a i {
    margin-right: 10px;
    color: var(--color-7);
}

footer h5 {
    text-align: center;
    padding-bottom: 25px;
}
footer h5 i {
    color: var(--color-7);
}